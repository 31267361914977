<template>
  <div>
    <section class="section position-relative">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <accountMenu />
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="title">เครดิตพอยท์</h3>
              <template v-if="enableOmiseFeature">
                <b-button @click="showFormAdd" variant="outline-primary"
                  >เติมเครดิตพอยท์</b-button
                >
              </template>
            </div>

            <p class="border-bottom mt-2"></p>

            <div class="mb-3">
              <h5>สถิติการใช้งาน</h5>
            </div>
            <b-container fluid>
              <b-row>
                <b-col>
                  <div class="text-center">
                    <h4>
                      {{ creditPointData != null ? creditPointData.point : 0 }}
                    </h4>
                    <div>พอยท์คงเหลือ</div>
                  </div>
                </b-col>
                <b-col>
                  <div class="text-center">
                    <h4>0</h4>
                    <div>พอยท์ที่ใช้งานแล้ว</div>
                  </div>
                </b-col>
              </b-row>
            </b-container>

            <div class="mb-2 mt-4">
              <h5>ประวัติการซื้อพอยท์</h5>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive bg-white">
                  <v-data-table
                    :headers="headers"
                    :items-per-page="10"
                    :items="billingHistory"
                    class="table table-center table-padding mb-0"
                  >
                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                      {{ item.amount | numFormat("0,0.00") }}
                    </template>
                    <template v-slot:item.point="{ item }">
                      {{ item.point | numFormat("0,0") }}
                    </template>
                    <template v-slot:item.paid="{ item }">
                      <!-- <check-circle-icon
                        v-if="item.paid"
                        size="1.5x"
                        class="text-primary"
                      ></check-circle-icon>
                      <x-circle-icon
                        v-else
                        size="1.5x"
                        class="text-danger"
                      ></x-circle-icon> -->
                      <div v-if="item.paid" class="badge badge-custom">
                        <check-circle-icon
                          size="1.5x"
                          class="mr-2"
                        ></check-circle-icon
                        >paid
                      </div>
                      <div v-else class="badge badge-custom2">
                        <x-circle-icon size="1.5x" class="mr-2"></x-circle-icon
                        >failed
                      </div>
                    </template>
                    <template v-slot:item.receipt="{ item }">
                      <router-link
                        :to="{
                          name: 'Receipt',
                          params: { id: item._id },
                        }"
                        v-slot="{ href, navigate }"
                        custom
                        target="_blank"
                      >
                        <a
                          :href="href"
                          @click="navigate"
                          class="btn btn-icon btn-outline-primary"
                        >
                          <i class="uil uil-invoice"></i>
                        </a>
                      </router-link>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
      </div>

      <b-overlay :show="overlay" no-wrap> </b-overlay>
    </section>

    <b-modal
      id="modal-add-point"
      title="เติมเครดิตพอยท์"
      centered
      v-model="modalAdd"
      @ok="submitForm"
      :ok-title="'ตกลง'"
      :cancel-title="'ยกเลิก'"
    >
      <b-form novalidate>
        <b-form-group label="จำนวน:" description="มีภาษีมูลค่าเพิ่มอีก 7%">
          <b-form-input
            v-model.number="model.amount"
            type="number"
            placeholder="ระบุจำนวนพอยท์ (฿1 = 1Point)"
            required
            max="1000000"
          ></b-form-input>
        </b-form-group>
      </b-form>

      <div class="mt-4">
        <h6>เลือกชำระเงิน</h6>
      </div>
      <div class="row no-gutters mt-3">
        <template v-for="(card, idx) of paymentMethods">
          <div class="col-12 py-1" :key="`customer_card_${idx}`">
            <div class="row no-gutters">
              <div class="col-6">
                <div class="custom-control custom-radio custom-control-inline">
                  <div class="form-group mb-0">
                    <input
                      type="radio"
                      :id="`exist_card_${idx}`"
                      :name="`exist_card_${idx}`"
                      class="custom-control-input"
                      :value="card.id"
                      v-model="model.card"
                    />
                    <label
                      :for="`exist_card_${idx}`"
                      class="custom-control-label"
                      >{{ card.name }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-6">
                {{ card.brand }}
                **** {{ card.last_digits }}
                <!-- (expires
                {{ card.expiration_month.toString().padStart(2, 0) }}/{{
                  card.expiration_year
                }}) -->
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from "vue-feather-icons";

import omiseService from "../../../services/omiseService";
import { getCreditPointData } from "../../../services/infoService";
import accountMenu from "../menu";
export default {
  components: {
    CheckCircleIcon,
    XCircleIcon,
    accountMenu,
  },
  data: () => ({
    headers: [
      {
        text: "เลขใบแจ้งหนี้",
        align: "center",
        sortable: false,
        value: "invoiceNo",
      },
      {
        text: "วันที่",
        align: "center",
        sortable: false,
        value: "created_at",
      },
      {
        text: "จำนวนเงิน (THB)",
        align: "right",
        sortable: false,
        value: "amount",
      },
      {
        text: "พอยท์",
        align: "right",
        sortable: false,
        value: "point",
      },
      {
        text: "สถานะ",
        align: "center",
        sortable: false,
        value: "paid",
      },
      {
        text: "ใบเสร็จ",
        align: "center",
        sortable: false,
        value: "receipt",
      },
    ],
    billingHistory: [],
    modalAdd: false,
    paymentMethods: [],
    overlay: false,
    progressModal: false,
    model: {
      amount: 0,
      card: null,
    },
    creditPointData: null,
  }),
  mounted() {
    this.init();
  },
  computed: {
    enableOmiseFeature() {
      return this.$store.state.enableOmiseFeature || false;
    },
  },
  watch: {
    modalAdd(val) {
      if (val == false) {
        this.model.card = null;
        this.model.amount = 0;
      }
    },
  },
  methods: {
    init() {
      this.overlay = true;
      let reqs = [
        omiseService.listCards(),
        getCreditPointData(),
        omiseService.listBillingHistory(),
      ];
      Promise.all(reqs)
        .then((resps) => {
          const respCards = resps[0];
          if (respCards.data.status && respCards.data.result.total > 0) {
            this.paymentMethods = respCards.data.result.data;
          }

          const respCreditPoint = resps[1];
          this.creditPointData = respCreditPoint.data;

          const respBilling = resps[2];
          if (respBilling.data.status) {
            this.billingHistory = respBilling.data.result;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;

          if (this.$route.query.form == true) {
            this.showFormAdd();
          }
        });
    },
    submitForm(e) {
      let modalId = e.componentId;
      if (this.model.amount == 0 || this.model.amount == null) {
        e.preventDefault();
        this.$bvModal.msgBoxOk("กรุณาระบุจำนวนเครดิตพอยท์", {
          centered: true,
          size: "sm",
          okTitle: "ฉันเข้าใจแล้ว",
        });
        return;
      }

      if (this.model.card == "" || this.model.card == null) {
        e.preventDefault();
        this.$bvModal.msgBoxOk("กรุณาเลือกการชำระเงิน", {
          centered: true,
          size: "sm",
          okTitle: "ฉันเข้าใจแล้ว",
        });
        return;
      }

      this.overlay = true;
      omiseService
        .charge(this.model)
        .then((resp) => {
          if (resp.data.failureCode != null && resp.data.paid == false) {
            this.$bvModal.msgBoxOk(resp.data.failureMessage, {
              title: "Error",
              centered: true,
              okVariant: "danger",
            });
          } else {
            this.init();
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    showFormAdd() {
      if (this.paymentMethods.length == 0) {
        this.$bvModal
          .msgBoxOk(
            "ท่านยังไม่ได้เพิ่มบัตรเครดิตสำหรับชำระเงิน กรุณาเพิ่มบัตรเครดิตก่อนที่จะดำเนินการต่อไป",
            {
              title: "ต้องการบัตรเครดิตสำหรับชำระเงิน",
              centered: true,
              okTitle: "ตกลง",
            }
          )
          .then(() => {
            this.$router.push({
              path: "/account/creditcard",
            });
          });
      } else {
        this.modalAdd = true;
      }
    },
  },
};
</script>